<template lang="pug">
.icon.k-icon
  i(:class="iconClass")
</template>

<script setup>
import { computed } from 'vue'
const classMap = {
  'mdi-calender': 'mdi mdi-calendar',
  'mdi-shopping': 'mdi mdi-shopping',
  'mdi-wallet-giftcard': 'mdi mdi-wallet-giftcard',
  'mdi-account-multiple': 'mdi mdi-account-multiple',
  'mdi-share': 'mdi mdi-share-variant',
  'mdi-calendar': 'mdi mdi-calendar',
  'mdi-map': 'mdi mdi-map',
  'mdi-map-marker': 'mdi mdi-map-marker',
  'mdi-receipt': 'mdi mdi-receipt',
  'mdi-chevron-left': 'mdi mdi-chevron-left',
  'mdi-chevron-right': 'mdi mdi-chevron-right',
  'mdi-account-box-outline': 'mdi mdi-account-box-outline',
  'mdi-cog': 'mdi mdi-cog',
  'mdi-plus': 'mdi mdi-plus',
  'mdi-close-circle-outline': 'mdi mdi-close-circle-outline',
  'fa-caret-up': 'fa fa-caret-up',
  'fa-caret-down': 'fa fa-caret-down',
  'fa-check': 'fa fa-check',
  'fa-dollar': 'fa fa-dollar',
  'fa-check': 'fa fa-check',
  'fa-user': 'fa fa-user',
  'fa-users': 'fa fa-users',
  'fa-user-circle': 'fa fa-user-circle',
  'fa-calendar': 'fa fa-calendar',
  'fa-plus': 'fa fa-plus',
  'fa-pencil': 'fa fa-pencil',
  'fa-cart': 'fa fa-shopping-cart',
  'fa-close': 'fa fa-close',
  'fa-exclamation-triangle': 'fa fa-exclamation-triangle',
  'fa-arrow-down': 'fa fa-arrow-down',
  'fa-arrow-up': 'fa fa-arrow-up',
  'fa-question-circle': 'fa fa-question-circle',
  'fa-trash': 'fa fa-trash',
  'fa-heart': 'fa fa-heart',
  'fa-heart-o': 'fa fa-heart-o',
  'fa-search': 'fa fa-search',
  'fa-map': 'fa fa-map',
  'fa-map-marker': 'fa fa-map-marker',
  'fa-chevron-right': 'fa fa-chevron-right'
}

const props = defineProps({
  icon: { type: String, required: true }
})

const iconClass = computed(() => {
  return classMap[props.icon]
})
</script>
