import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import FetchingStatsDataOptionService from '@services/fetch_stats_data_options_service'
import ExtraQueryStringParseService from '../services/extra_query_string_parse_service.js'
import { convertToFormData } from '@shared/composables/use_form_data_converter'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'partners',
  attributes: [
    'id',
    'email',
    'name',
    'is_suspended',
    'products_count',
    'product_sku_prefix',
    'created_at',
    'updated_at',
    'tag_list',
    'setting_fee_paid_at',
    'subscription_started_at',
    'subscription_ended_at',
    'current_stored_values',
    'admin_note',
    'id_front_photo',
    'id_back_photo',
    'e_signature',
    'account_photo',
    'company_account_photo',
    'last_sign_in_at',
    'video_id'
  ],
  editableAttributes: [
    'email',
    'name',
    'ori_password',
    'password',
    'new_password',
    'password_confirmation',
    'product_sku_prefix',
    'tag_list'
  ]
}

import PartnerProfile from './partner_profile.js'

export default class Partner extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)

    this.profile = new PartnerProfile(attributes.profile)
  }

  static all(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  static fetchStats(options = {}) {
    let queryString = FetchingStatsDataOptionService.call(options)

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  static getCurrentPartner() {
    return axios.get(`${new this().apiBasePath()}/current_partner`)
  }

  /**
   * 將此 partner 停權
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  suspend() {
    return axios.put(`${this.apiBasePath()}/${this.id}/suspend`)
  }

  updateSubscriptionStartedAt(subscriptionStartedAt) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/update_subscription_started_at`,
      {
        data: {
          type: 'update-subscription-started-at',
          attributes: {
            subscription_started_at: subscriptionStartedAt
          }
        }
      }
    )
  }

  fetchProducts(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/products?${FetchingDataOptionsService.call(options)}`
    )
  }

  getProduct(productId) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products/${productId}`)
  }

  createProduct(productForm) {
    const requestBody = {
      data: {
        type: 'products',
        attributes: productForm
      }
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/products`, requestBody)
  }

  updateProduct(productId, productForm) {
    const requestBody = {
      data: {
        type: 'products',
        attributes: productForm
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/products/${productId}`,
      requestBody
    )
  }

  fetchProductPreferences(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/product_preferences?${FetchingDataOptionsService.call(options)}`
    )
  }

  // orders
  static fetchOrderStats(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(`${new this().apiBasePath()}/orders/stats?${queryString}`)
  }

  fetchOrders(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/orders?${FetchingDataOptionsService.call(options)}`
    )
  }

  getOrder(token) {
    return axios.get(`${this.apiBasePath()}/${this.id}/orders/${token}`)
  }

  fetchShippingCategories(options = {}) {
    let query = `${FetchingDataOptionsService.call(options)}`
    if (options.additional_id)
      query += `&additional_id=${options.additional_id}`

    return axios.get(
      `${this.apiBasePath()}/${this.id}/shipping_categories?${query}`
    )
  }

  saveShippingCategory(shippingCategory) {
    const requestBody = {
      data: {
        type: 'create-shipping-category',
        attributes: shippingCategory.attributes()
      }
    }

    if (shippingCategory.isNewRecord()) {
      return axios.post(
        `${this.apiBasePath()}/${this.id}/shipping_categories`,
        requestBody
      )
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/shipping_categories/${
        shippingCategory.id
      }`,
      requestBody
    )
  }

  destroyShippingCategory(id) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/shipping_categories/${id}`
    )
  }

  fetchShippingMethodShips(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/shipping_method_ships?${FetchingDataOptionsService.call(options)}`
    )
  }

  createShippingMethodShip({ shippingMethodId }) {
    return axios.post(
      `${this.apiBasePath()}/${
        this.id
      }/shipping_method_ships?shipping_method_id=${shippingMethodId}`
    )
  }

  updateShippingMethodShipPrice({ id, form }) {
    return axios.put(
      `${this.apiBasePath()}/${
        this.id
      }/update_shipping_method_ship_price/${id}`,
      {
        data: {
          type: 'update-shipping-method-ship-price',
          attributes: form
        }
      }
    )
  }

  destroyShippingMethodShip(id) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/shipping_method_ships/${id}`
    )
  }

  fetchPromoterMatchAnswerShares(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_answered_shares?${FetchingDataOptionsService.call(
        options
      )}`
    )
  }

  fetchPromoterMatchAnsweredShareStats(options) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_answered_shares/stats?${queryString}`
    )
  }

  fetchPromoterMatchAnswers(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_answers?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchPromoterMatchProducts(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_products?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchPromoterMatchProductStats(options) {
    let queryString = FetchingDataOptionsService.call(options)

    queryString += `&x_field=${options.x_field}`

    options.y_fields.forEach((yField) => {
      queryString += `&y_fields[]=${yField}`
    })

    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_match_products/stats?${queryString}`
    )
  }

  fetchMembers(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/members?${FetchingDataOptionsService.call(options)}`
    )
  }

  createPromoterCampaign(form) {
    const requestBody = {
      data: {
        type: 'products',
        attributes: form
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/promoter_campaigns`,
      requestBody
    )
  }

  fetchPromoterCampaigns(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_campaigns?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchBrands(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/brands?${FetchingDataOptionsService.call(options)}`
    )
  }

  batchSaveCampaignShareShips({ promoterShareIds }) {
    const requestBody = {
      data: {
        type: 'batch-save-promoter-campaign-share-ships',
        attributes: {
          share_ids: promoterShareIds
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_campaign_share_ships/batch_save`,
      requestBody
    )
  }

  updateTagList() {
    const requestBody = {
      data: {
        type: 'partners',
        attributes: {
          tag_list: this.tag_list
        }
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/tag_list`, requestBody)
  }

  get oddPayApiBasePath() {
    return `${OPTIONS.apiPath}/${OPTIONS.apiVersion}/${OPTIONS.scope}/odd_pay/partners`
  }

  static choosePlan(oddPayInvoice) {
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${
        oddPayInvoice.id
      }/choose_plan`,
      oddPayInvoice.requestBody()
    )
  }

  static currentNormalInvoice() {
    return axios.get(`${new this().oddPayApiBasePath}/invoices/current_normal`)
  }

  static currentSubscriptionInvoice() {
    return axios.get(
      `${new this().oddPayApiBasePath}/invoices/current_subscription`
    )
  }

  static confirmOddPayInvoice(oddPayInvoice) {
    const requestBody = {
      data: {
        type: 'odd_pay_invoices',
        attributes: {
          email: oddPayInvoice.email,
          name: oddPayInvoice.name,
          contact_phone: oddPayInvoice.contact_phone,
          company_name: oddPayInvoice.company_name,
          company_ein: oddPayInvoice.company_ein,
          address: oddPayInvoice.address,
          note: oddPayInvoice.note,
          payment_method_id: oddPayInvoice.payment_method_id
        }
      }
    }
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${oddPayInvoice.id}/confirm`,
      requestBody
    )
  }

  static makePaymentForOddPayInvoice(oddPayInvoice) {
    const requestBody = {
      data: {
        type: 'odd_pay_invoices',
        attributes: {
          payment_method_id: oddPayInvoice.payment_method_id
        }
      }
    }
    return axios.put(
      `${new this().oddPayApiBasePath}/invoices/${
        oddPayInvoice.id
      }/make_payment`,
      requestBody
    )
  }

  static fetchInvoices(options = {}) {
    return axios.get(
      `${
        new this().oddPayApiBasePath
      }/invoices?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchOddPayInvoices(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/odd_pay_invoices?${FetchingDataOptionsService.call(options)}`
    )
  }

  payOddPayInvoiceByCash(form) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/odd_pay_invoices/pay_by_cash`,
      {
        data: {
          type: 'odd_pay_invoice_pay_by_cash',
          attributes: form
        }
      }
    )
  }

  // reward_account
  fetchRewardAccounts() {
    return axios.get(`${this.apiBasePath()}/${this.id}/reward_accounts`)
  }

  // reward_partner_settlement
  fetchRewardPartnerSettlements(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/reward_partner_settlements?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchSubscriptionInfos(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/subscription_infos?${FetchingDataOptionsService.call(options)}`
    )
  }

  // industry_category_ships
  fetchIndustryCategoryShips(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/industry_category_ships?${FetchingDataOptionsService.call(options)}`
    )
  }

  batchSaveIndustryCategoryShips(form) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/industry_category_ships/batch_save`,
      {
        data: {
          type: 'batch-save-partner-industry-category-ships',
          attributes: form
        }
      }
    )
  }

  fetchIndustryPromoterShares(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/industry_promoter_shares?${FetchingDataOptionsService.call(options)}`
    )
  }

  static signUp(form) {
    return axios.post(`${new this().apiBasePath()}/sign_up`, {
      data: {
        type: 'sign-up',
        attributes: form
      }
    })
  }

  updateProfile(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile`,
      convertToFormData({
        data: {
          type: 'update-profile',
          attributes: form
        }
      })
    )
  }

  updatePassword(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/password`, {
      data: {
        type: 'update-password',
        attributes: form
      }
    })
  }

  updateVideo(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/video`,
      convertToFormData({
        data: {
          type: 'update-video',
          attributes: form
        }
      })
    )
  }

  updateAdminNote(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/admin_note`, {
      data: {
        type: 'update-admin-note',
        attributes: form
      }
    })
  }

  fetchPromoterMemberInterests(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/promoter_member_interests?${FetchingDataOptionsService.call(options)}`
    )
  }

  updatePromoterMemberInterest(form) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/promoter_member_interests`,
      {
        data: {
          type: 'update-promoter-member-interest',
          attributes: form
        }
      }
    )
  }

  updateHomeCompletion(form) {
    return axios.post(`${this.apiBasePath()}/${this.id}/home_completion`, {
      data: {
        type: 'update-home-completion',
        attributes: form
      }
    })
  }
}
