import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import { createThisTypeNode } from 'typescript'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import { convertToFormData } from '@shared/composables/use_form_data_converter'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_members',
  attributes: [
    'id',
    'email',
    'name',
    'promoters',
    'role_ids',
    'profile',
    'bank_account_info',
    'reward_account',
    'created_at',
    'updated_at',
    'bg_image',
    'photo',
    'video_id',
    'rate',
    'is_toured_kolcenter',
    'verification'
  ],
  editableAttributes: [
    'email',
    'name',
    'password',
    'new_password',
    'ori_password',
    'password_confirmation',
    'role_ids',
    'profile',
    'bank_account_info',
    'edit_source',
    'media_accounts'
  ]
}

export default class PromoterMember extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  updatePassword() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/password`,
      this.requestBody()
    )
  }

  static signUp(model) {
    return axios.post(`${new this().apiBasePath()}/sign_up`, {
      data: {
        type: 'sign_up',
        attributes: model.attributes()
      }
    })
  }

  updateBgImage(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bg_image`,
      convertToFormData({
        data: {
          type: 'save-bg-image',
          attributes: form
        }
      })
    )
  }

  updateVideo(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/video`,
      convertToFormData({
        data: {
          type: 'save-video',
          attributes: form
        }
      })
    )
  }

  updatePhotos(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/photos`, formData)
  }

  updateProfile(promoterId) {
    const body = {
      promoter_id: promoterId,
      data: {
        type: 'promoter_profile',
        attributes: Object.assign(this.profile, {
          role_ids: this.role_ids,
          email: this.email,
          media_accounts: this.media_accounts
        })
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/profile`, body)
  }

  updateProfileV2(options = {}) {
    const apiPath = [this.apiBasePath(), this.id, 'profile', options.controller]
      .filter(Boolean)
      .join('/')

    return axios.put(apiPath, this.requestBody())
  }

  shotGunUpdate(attributes) {
    const body = {
      data: {
        type: 'promoter_member',
        attributes
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/shot_gun_update`, body)
  }

  getPhoneVerificationCodeSms(data) {
    const body = {
      id: this.id,
      data: {
        type: 'promoter_profiles',
        attributes: data
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/verify_phone/verification_code`,
      body
    )
  }

  verifyPhone(verification_code) {
    const body = {
      id: this.id,
      data: {
        type: 'promoter_profiles',
        attributes: {
          verification_code: verification_code
        }
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/verify_phone`, body)
  }

  // bank_account_info
  updateBankAccountInfo(promoterId) {
    const body = {
      promoter_id: promoterId,
      data: {
        type: 'promoter_account_info',
        attributes: Object.assign(this.bank_account_info, {
          sample_address: this.profile.sample_address,
          edit_source: this.edit_source
        })
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/bank_account_info`, body)
  }

  updateBankAccountInfoV2(options = {}) {
    const apiPath = [
      this.apiBasePath(),
      this.id,
      'bank_account_info',
      options.controller
    ]
      .filter(Boolean)
      .join('/')

    return axios.put(apiPath, this.requestBody())
  }

  updateBankAccountInfoCompany(bankAccountInfo) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bank_account_info/company`,
      {
        data: {
          type: 'bank-account-info-bank-account-info',
          attributes: bankAccountInfo
        }
      }
    )
  }

  updateBankAccountInfoResidenceAddress(bankAccountInfo) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bank_account_info/residence_address`,
      {
        data: {
          type: 'bank-account-info-residence-address',
          attributes: bankAccountInfo
        }
      }
    )
  }

  updateBankAccountInfoContactAddress(bankAccountInfo) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bank_account_info/contact_address`,
      {
        data: {
          type: 'bank-account-info-contact-address',
          attributes: bankAccountInfo
        }
      }
    )
  }

  updateProfileSampleAddress(bankAccountInfo) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/profile/sample_address`,
      {
        data: {
          type: 'bank-account-info-sample-address',
          attributes: bankAccountInfo
        }
      }
    )
  }

  updateBankAccountInfoTransferAccount() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bank_account_info/transfer_account`,
      this.requestBody()
    )
  }

  updateBankAccountInfoAccountPhoto(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bank_account_info/account_photo`,
      form
    )
  }

  updateBankAccountInfoIdPhoto(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bank_account_info/id_photo`,
      form
    )
  }

  updateBankAccountInfoESignature(formData) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/bank_account_info/e_signature`,
      formData
    )
  }

  toggleVerification(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_verification`, {
      data: {
        type: 'toggle-verification',
        attributes: form
      }
    })
  }

  static getCurrentKolShare() {
    return axios.get(`${new this().apiBasePath()}/current_kol_share`)
  }

  static getLineBotAccount() {
    return axios.get(`${new this().apiBasePath()}/line_bot_account`)
  }

  static getLineBotAccountLinkToken() {
    return axios.get(`${new this().apiBasePath()}/line_bot_account_link_token`)
  }

  hasPhoto(photoKey, photoValue) {
    return this[photoKey][photoValue] && this[photoKey][photoValue].url !== null
  }

  /**
   * 取得 promoterMember 評分紀錄
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  fetchRateRecords(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/rate_records?${FetchingDataOptionsService.call(options)}`
    )
  }

  /**
   * 將 promoterMember 評分
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  updateRate({ rate, note, is_sync_to_level }) {
    const requestBody = {
      data: {
        type: 'promoter-rate',
        attributes: {
          rate: rate,
          note: note,
          is_sync_to_level: is_sync_to_level
        }
      }
    }

    return axios.put(
      `${this.apiBasePath()}/${this.id}/update_rate`,
      requestBody
    )
  }

  /**
   * 更新導覽紀錄
   *
   * @returns {Promise} 回傳 response 或 errors
   */
  tourKolcenter() {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/tour_kolcenter`,
      this.requestBody()
    )
  }

  // media_account
  fetchMediaAccounts() {
    return axios.get(`${this.apiBasePath()}/${this.id}/media_accounts`)
  }

  saveMediaAccount(mediaAccount) {
    const requestBody = {
      data: {
        type: 'promoter-create-media-Info',
        attributes: mediaAccount
      }
    }

    if (mediaAccount.isNewRecord()) {
      return axios.post(
        `${this.apiBasePath()}/${this.id}/media_accounts`,
        requestBody
      )
    }
    return axios.put(
      `${this.apiBasePath()}/${this.id}/media_accounts/${mediaAccount.id}`,
      requestBody
    )
  }

  destroyMediaAccount(mediaAccountId) {
    return axios.delete(
      `${this.apiBasePath()}/${this.id}/media_accounts/${mediaAccountId}`
    )
  }

  // reward_accounts
  fetchRewardAccounts() {
    return axios.get(`${this.apiBasePath()}/${this.id}/reward_accounts`)
  }

  // buyout_order
  static fetchCurrentBuyoutOrder() {
    return axios.get(`${new this().apiBasePath()}/current_buyout_order`)
  }

  fetchBuyoutOrders({ options }) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/buyout_orders?${FetchingDataOptionsService.call(options)}`
    )
  }

  // preferred_category_ship
  fetchPreferredCategoryShips() {
    return axios.get(
      `${this.apiBasePath()}/${this.id}/preferred_category_ships`
    )
  }

  createPreferredCategoryShip(options) {
    const requestBody = {
      data: {
        type: 'promoter-member-create-preferred-category-ship',
        attributes: {
          preferred_category_id: options.preferred_category_id
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/preferred_category_ships`,
      requestBody
    )
  }

  batchSavePreferredCategoryShips(form) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/preferred_category_ships/batch_save`,
      {
        data: {
          type: 'batch-save-promoter-member-preferred-category-ships',
          attributes: form
        }
      }
    )
  }

  destroyPreferredCategoryShip(preferredCategoryShipId) {
    return axios.delete(
      `${this.apiBasePath()}/${
        this.id
      }/preferred_category_ships/${preferredCategoryShipId}`
    )
  }

  static completeCurrentBuyoutOrder({ form }) {
    return axios.post(
      `${new this().apiBasePath()}/current_buyout_order/complete`,
      {
        data: {
          type: 'create-buyout-order-item',
          attributes: form
        }
      }
    )
  }

  static createCurrentBuyoutOrderItem({ form }) {
    return axios.post(
      `${new this().apiBasePath()}/current_buyout_order/items`,
      {
        data: {
          type: 'create-buyout-order-item',
          attributes: form
        }
      }
    )
  }

  static updateCurrentBuyoutOrderItem({ id, form }) {
    return axios.put(
      `${new this().apiBasePath()}/current_buyout_order/items/${id}`,
      {
        data: {
          type: 'create-buyout-order-item',
          attributes: form
        }
      }
    )
  }

  static destroyCurrentBuyoutOrderItem({ id }) {
    return axios.delete(
      `${new this().apiBasePath()}/current_buyout_order/items/${id}`
    )
  }

  getMatchInfo() {
    return axios.get(`${this.apiBasePath()}/${this.id}/match_info`)
  }

  fetchIndustryCategoryPartners(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/industry_category_partners?${FetchingDataOptionsService.call(options)}`
    )
  }

  fetchPartnerInterests(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/partner_interests?${FetchingDataOptionsService.call(options)}`
    )
  }

  updatePartnerInterest(form) {
    return axios.post(`${this.apiBasePath()}/${this.id}/partner_interests`, {
      data: {
        type: 'update-partner-interest',
        attributes: form
      }
    })
  }

  fetchInterestedPartners(options) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/interested_partners?${FetchingDataOptionsService.call(options)}`
    )
  }
}
