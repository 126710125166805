<template lang="pug">
.k-card(
  :is="component"
  :options="options"
)
  template(
    #header-wrapper
    v-if="$slots['header-wrapper']"
  )
    slot(name="header-wrapper")

  template(#image-wrapper)
    slot(name="image-wrapper")

  template(
    #image-info-wrapper
    v-if="$slots['image-info-wrapper']"
  )
    slot(name="image-info-wrapper")

  template(
    #overlay-image-wrapper
    v-if="$slots['overlay-image-wrapper']"
  )
    slot(name="overlay-image-wrapper")

  template(#info-wrapper)
    slot(name="info-wrapper")

  template(
    #footer-wrapper
    v-if="$slots['footer-wrapper']"
  )
    slot(name="footer-wrapper")
</template>

<script setup>
import { ref, computed } from 'vue'
import CampaignCardV1 from './k_card/campaign-card-v1.vue'
import CampaignCardV2 from './k_card/campaign-card-v2.vue'
import campaignNewCardV1 from './k_card/campaign-new-card-v1.vue'
import KolCardV1 from './k_card/kol-card-v1.vue'

const STYLE_TYPE_MAP = {
  'campaign-card-v1': CampaignCardV1,
  'campaign-card-v2': CampaignCardV2,
  'campaign-new-card-v1': campaignNewCardV1,
  'kol-card-v1': KolCardV1
}

const props = defineProps({
  styleType: { type: String },
  options: { type: Object }
})

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})
</script>
