import * as types from './mutation-types'
import Partner from '../../resource_models/partner.js'
export * from './products/actions.js'
export * from './product_preference/actions.js'
export * from './promoter_shares/actions.js'
export * from './promoter_match_answers/actions.js'
export * from './promoter_match_products/actions.js'
export * from './promoter_campaigns/actions.js'
export * from './promoter_campaign_share_ships/actions.js'
export * from './orders/actions.js'
export * from './odd_pay/actions.js'
export * from './partner_subscription_infos/actions.js'
export * from './reward_accounts/actions.js'
export * from './shipping_categories/actions.js'
export * from './shipping_method_ships/actions.js'
export * from './reward_partner_settlements/actions.js'
export * from './partner_members/actions.js'
export * from './partner_industry_category_ships/actions.js'
export * from './brands/actions.js'
export * from './videos/actions.js'
export * from './partner_promoter_member_interests/actions.js'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    Partner.all(options)
      .then((response) => {
        commit(types.FETCH_PARTNERS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    Partner.find(id)
      .then((response) => {
        commit(types.GET_PARTNER_SUCCESS, response)

        dispatch('videos/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PARTNER_SUCCESS, response)
        } else {
          commit(types.UPDATE_PARTNER_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PARTNER_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchStats = ({ dispatch, commit }, { statsKey, options }) => {
  commit(types.API_REQUEST_START, 'fetchStats')

  return new Promise((resolve, reject) => {
    Partner.fetchStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const getCurrentPartner = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    Partner.getCurrentPartner()
      .then((response) => {
        commit(types.GET_PARTNER_SUCCESS, response)

        commit(types.SET_CURRENT_PARTNER_ID, response.data.data.id)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getCurrentPartner,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

/**
 * toggle Partner `is_suspended` 的狀態
 *
 * @param {number} id 指定的 resource ID
 * @returns {promise} response or errors
 */
export const suspend = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'suspend')

  return new Promise((resolve, reject) => {
    model
      .suspend()
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: suspend,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateSubscriptionStartedAt = (
  { dispatch, commit },
  { model, subscriptionStartedAt }
) => {
  commit(types.API_REQUEST_START, 'suspend')

  return new Promise((resolve, reject) => {
    model
      .updateSubscriptionStartedAt(subscriptionStartedAt)
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSubscriptionStartedAt,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateTagList = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateTagList')

  return new Promise((resolve, reject) => {
    model
      .updateTagList()
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateTagList,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PARTNERS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PARTNER_SUCCESS, response)

    resolve(response)
  })
}

export const signUp = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    Partner.signUp(form)
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: signUp,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfile = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateProfile')

  return new Promise((resolve, reject) => {
    model
      .updateProfile(form)
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfile,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updatePassword = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updatePassword')

  return new Promise((resolve, reject) => {
    model
      .updatePassword(form)
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfile,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateAdminNote = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateAdminNote')

  return new Promise((resolve, reject) => {
    model
      .updateAdminNote(form)
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateAdminNote,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateHomeCompletion = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateHomeCompletion')

  return new Promise((resolve, reject) => {
    model
      .updateHomeCompletion(form)
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateHomeCompletion,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
